jQuery(document).ready(function($) {
	currentRequest = null;
	var typingTimer;
	var doneTypingInterval = 500;

	/* Keep the previous value to avoid unnecessary searches */
	$('#flight-info').keydown(function(e) {
		$(e.target).data('previousValue', $(e.target).val());
	});

	$("#flight-info").keyup(function(e) {
		if ($(e.target).data('previousValue') != $(e.target).val()) {
			showLoadingImage()
			clearTimeout(typingTimer);
			typingTimer = setTimeout(sendRequest, doneTypingInterval);
		}
	});

	$("#find-flight-form").submit(function(event) {
		event.preventDefault();
		showLoadingImage()
		sendRequest();
	});
});

function showLoadingImage() {
	if (!$(".find-flight-tool").hasClass('show')) {
		$(".find-flight-tool").addClass("show");
	}
	$(".find-flight-tool").addClass('keypress');

	$('.find-flight-search-results-wrap').html('<div class="flight-row loading"><img src="/local/modules/weather/assets/images/loading.svg" alt="loading" /></div>');
};

function sendRequest() {
	currentRequest = flightsQuickSerach(currentRequest);
};

function flightsQuickSerach(currentRequest) {
	currentRequest = $.ajax({
		type: 'POST',
		url: "/api/flights/quicksearch",
		data: { q: $("#flight-info").val() },
		beforeSend: function() {
			if (currentRequest != null) {
				currentRequest.abort();
			}
		}
	})
		.done(function(data) {
			var objResultsContainer = $('.find-flight-search-results-wrap');
			if (data.MESSAGE.length > 0) {
				objResultsContainer.html('<div class="flight-row">&nbsp;&nbsp;&nbsp;&nbsp;' + data.MESSAGE + '</div>');
			}
			else if (data.RESULTS.length > 0) {
				var strResultRow = '';
				$.each(data.RESULTS, function(i, result) {
					var blnIsEven = false;
					if ((i % 2) === 0) {
						blnIsEven = true;
					}
					// debugger;
					strResultRow += '<div class="flight-row ' + (blnIsEven ? 'tint-row' : '') + '" tabindex="0">';
					strResultRow += '<div class="table-cell dd-type"><div class="inside-table-text-2">';
					if ( result.TYPE === 'Arrivals' ) {
						strResultRow += '<img class="flight-type" title="Arrivals" alt="Arrivals" src="/20170713122722/local/public/shared/assets/images/websites/arrivals_blue3.svg" />';
					}
					else if ( result.TYPE === 'Departures' ) {
						strResultRow += '<img class="flight-type" title="Departures" alt="Departures" src="/20170713122722/local/public/shared/assets/images/websites/departures_blue4.svg" />';
					}
					strResultRow += '</div></div>';
					strResultRow += '<div class="table-cell dd-time-date"><div class="inside-table-text-2">';
					strResultRow += result.DATE + ' ' + result.TIME + '</div>';
					strResultRow += '<div class="inside-table-text-2 second-line">';
					strResultRow += result.AIRPORT + '</div>';
					strResultRow += '</div><div class="table-cell dd-airline"><div class="inside-table-text-2">';
					strResultRow += result.AIRLINE + '</div>';
					strResultRow += '</div><div class="table-cell dd-airport-flight"><div class="inside-table-text-2">';
					strResultRow += result.ORIGIN + '</div>';
					strResultRow += '<div class="inside-table-text-2 second-line">';
					strResultRow += result.IATA + result.FLIGHTNO + ((result.IATA2.length > 0) ? ' ' + result.IATA2 + result.FLIGHTNO2 : '') + '</div>';
					strResultRow += '</div><div class="table-cell dd-status"><div class="inside-table-text-2">';
					strResultRow += result.REMARK + '</div>';
					strResultRow += '</div><div class="table-cell dd-notification"><div class="inside-table-text-2">';
					strResultRow += '<img class="bt_twitter_img bt_bizTweet" src="/local/modules/flights/assets/images/bizTweet/twitter.svg" alt="' + result.NOTIFICATION + '|101" />';
					strResultRow += '<img class="bt_mme_url bt_bizTweet" src="/local/modules/flights/assets/images/bizTweet/messenger.svg" alt="' + result.NOTIFICATION + '|201" />';
					strResultRow += '<img class="bt_viber_img bt_bizTweet" src="/local/modules/flights/assets/images/bizTweet/viber-icon.svg" alt="' + result.NOTIFICATION + '|501" />' + '</div>';
					strResultRow += '</div></div>';
				});
				setTimeout(function() {
					objResultsContainer.html(strResultRow);
					bindNotifications('.flight-search-dd-widget-2', language);
				}, 1000);
			}
		})
		.fail(function() {
			var objResultsContainer = $('.find-flight-search-results-wrap');
			objResultsContainer.html('<div class="flight-row">An error occured while retrieving the flights</div>');
		})
		.always(function() {
			if (!$(".find-flight-tool").hasClass('show')) {
				$(".find-flight-tool").addClass("show");
			}
			$(".find-flight-tool").addClass('keypress');
		})

	return currentRequest;
};

function bindNotifications(parentID, language) {
	$(parentID + " .bt_twitter_img").btregister({
		bizTweetId: twbizTweetId,
		language: language
	});

	$(parentID + " .bt_mme_url").btmMe({
		page: messengerPage,
		holder: '[EID] [EDT]'
	});

	$(parentID + " .bt_viber_img").btViberRegister({
		botURI: viberBotURI,
		language: language
	});
}
